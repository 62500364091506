<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-01-11 15:53:05
-->
<template>
    <div>
        <div class="cover">
            <div class="page">
                <div class="page_l">
                    <div class="t1">{{ $t('N_I_235') }}</div>
                    <div class="t2">{{ userInfo.memberName }}</div>
                </div>
                <div class="page_r">
                    <div class="page_i bgd1" @click="goAct">
                        <img class="hua" src="@/assets/images/hua.gif" alt="">
                        <div class="t3">{{ $t('N_I_236') }}</div>
                        <div class="t4">{{ $t('N_I_237') }}</div>
                        <!-- <div class="dian"></div> -->
                    </div>
                    <!-- <div class="page_i bgd2">
                        <div class="t3">会员积分</div>
                        <div class="t4">目前积分：2311</div>
                        <div class="dian"></div>
                      </div> -->
                    <div class="page_i bgd3" @click="goNotice">
                        <img class="laba" src="@/assets/images/laba.gif" alt="">
                        <div class="t3">{{ $t('N_I_238') }}</div>
                        <div class="t4">{{ $t('N_I_239') }}</div>
                        <!-- <div class="dian"></div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="zhan"></div> -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(['userInfo']),
    },
    methods: {
        goAct() {
            this.$router.push({
                path: '/marketDynamics',
                query: { id: 7 },
            });
        },
        goNotice() {
            this.$router.push({
                path: '/noticLists',
                query: {
                    index: 1,
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.cover {
    // position: fixed;
    width: 100%;
    background: #fff;
    z-index: 10;
}

.zhan {
    // height: 77px;
}

.page {
    height: 67px;
    padding: 10px 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 10px solid rgba(7, 7, 7, 0.05);
}

.page_l {
    display: flex;
    align-items: center;
}

.page_r {
    display: flex;
    align-items: center;
}

.bgd1 {
    background: url('../assets/images/bgd1.png');
    animation: wiggle 1s ease-in-out infinite;
}

// .bgd2 {
//   background: url('../assets/images/bgd2.jpg');
// }
.bgd3 {
    background: url('../assets/images/bgd3.png');
    animation: wiggle 1s ease-in-out infinite;
}

.page_i {
    width: 107px;
    height: 63px;
    border-radius: 8px 8px 8px 8px;
    background-size: 100%;
    text-align: center;
    margin-left: 60px;
    position: relative;
    
    cursor: pointer;
    .hua {
        position: absolute;
        width: 107px;
        height: 63px;
        left: 0;
    }
    .laba {
        position: absolute;
        width: 50px;
        height: 50px;
        right: -15px;
        top: -10px;
        // animation: biged 0.4s ease-in-out infinite;
    }
}

.t1 {
    font-size: 26px;
    font-weight: 400;
    color: #6d6d6d;
    margin-right: 20px;
}

.t2 {
    font-size: 34px;
    font-weight: 600;
    color: #333333;
}

.t3 {
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    margin: 8px 0;
}

.t4 {
    font-size: 10px;
    color: #ffffff;
}

.dian {
    position: absolute;
    width: 12px;
    height: 12px;
    background: #fe0000;
    opacity: 1;
    border: 4px solid #ffffff;
    border-radius: 50%;
    top: -10px;
    right: -10px;
}

@keyframes wiggle {
  0% {
      transform: scale(1.1);
    }
    25% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(1.3);
    }
    75% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1.1);
    }
}
// @keyframes wiggle {
//     0% {
//         left: 0;
//     }
//     25% {
//         left: -5px;
//     }
//     50% {
//         left: 0;
//     }
//     75% {
//         left: 5px;
//     }
//     100% {
//         left: 0;
//     }
// }
// @keyframes biged {
//     0% {
//       transform: scale(1.1);
//     }
//     25% {
//       transform: scale(1.2);
//     }
//     50% {
//       transform: scale(1.3);
//     }
//     75% {
//       transform: scale(1.2);
//     }
//     100% {
//       transform: scale(1.1);
//     }
// }
</style>